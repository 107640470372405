.nav-link{
    color: #fff;
    font-size: large;
    /* margin-right: 40px; */
    font-weight: 500;
    align-items: end;
  margin-left: 25px;
}

.nav-link:hover{
    color: #FFD700;
    /* text-decoration: underline; */
    text-underline-offset: 4px;
    /* color: #fff; */
} 

.nav-link:focus{
    color: #FFD700;

}

.login-button {
    width: 150px; 
    color: #ffffff;           /* White text */
    border: 2px #fff solid;             /* No border */
    border-radius: 25px;      /* Rounded corners */
    padding: 0px;       /* Padding around text */
    font-size: 16px;          /* Text size */
    font-family: Arial, sans-serif; /* Font family */
    cursor: pointer;          /* Pointer cursor on hover */
    outline: none;            /* No outline when focused */
    position: relative;       /* Positioning context for the arrow */
    overflow: hidden;   
    margin-top: 4px;      /* Keeps the pseudo-element inside the button */
  } 
   
  .login-button:hover {
    
    color: yellow;           
    border: 2px solid yellow ;  
  } 


.nav-login{
    color: #fff;
    background: none;
    border: 1px solid #fff;
    padding: 0px 0px 0px 35px;
    border-radius: 30px;
}
.left-arrow {
    height: 40px;
    width: 50px;
    margin: 0;
    margin-left: 10px;
    transform: rotate(180deg); /* Adjust the angle as needed */
    transition: transform 0.3s; /* Optional: smooth transition on hover */
    display: none;
}

.login-button:hover .left-arrow {
    transform: rotate(180deg); /* Rotate again on hover if desired */
}


.nav-item .nav-link.active {
    color: #FFD700;; /* Customize the active link color */
    font-weight: bold;
  }