 .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
     background: url('../assets/images/loginback.png') no-repeat center center fixed;
     background-size: cover;
  }
  
  .login-box {
    background: #DDD5CC;
    padding-top: 0px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 500px;
    opacity: 90%;
  }
  
  .login-title {
    text-align: center;
    margin-bottom: 20px;
    background-color: #086351;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .btn-block {
    width: 160%;
  }
  @media(max-width:786px){
    .btn-block {
        width: 100%;
      }
  }
  
  .btn-login {
    background-color: #086351;
    color: #fff;
  }
  
  .btn-login:hover {
    background-color: #086351;
  
      color: white; /* Change text color to white on hover */
  
  }
  
  .login-footer {
    margin-top: 10px;
    text-align: center;
  }
  
  .forgot-password {
    display: block;
    margin-bottom: 100px;
    text-align: right;
    color: #000;
    text-decoration: none;
  
  }
  
  .login-form {
    padding: 20px 50px;
    border-radius: 0px 0px 10px 10px;
    /* opacity: 90%; */
    display: grid;
    justify-content: center;
    width:75%
  }
  @media(max-width:786px){
    .login-form {
        padding: 20px 50px;
        border-radius: 0px 0px 10px 10px;
        /* opacity: 90%; */
        display: grid;
        justify-content: center;
        width:100%
    }
  }
  
  .eye-icon {
    margin-top: -26px;
    display: flex;
    justify-content: end;
    margin-right: -100px;
    margin-bottom: 30px;
}
.eye-icons {
  margin-top: -34px;
  display: flex;
  justify-content: end;
  margin-right: 10px;
  margin-bottom: 30px;
}
@media(max-width:786px){
    .eye-icon {
        margin-right: 10px;
    }

}
  
  .login-register {
    color: #000;
  }
  
  .form-controls {
    background-color: #DDD5CC;
    border: black 2px solid;
    margin-top: -15px;
    z-index: -1;
    display: grid;
    justify-content: center;
    width:160%;
    color: #000;
   
   
  }
  @media(max-width:786px){
    .form-controls {
        background-color: #DDD5CC;
        border: black 2px solid;
        margin-top: -15px;
        z-index: -1;
        display: grid;
        justify-content: center;
        width:100%;
    }
  }
  .text-view {
    background-color: #DDD5CC;
    color: #000;
    width: fit-content;
  
  }
  .label-over {
    margin-left: 10px;
    position: relative;
    z-index: 1;
  }
  .form-controls:focus{
    color: #000;
  }