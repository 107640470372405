.POCtable {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    border: 1px dashed black;
  }
  .POCtable th, td {
     text-align: center;
  }
  .POCtable tbody tr,td {
    border-top: 1px dashed #a19393;
    border-bottom: 1px dashed #a19393;
  }
  .poc_gst{
    margin-top: 20px;
    text-align: end;
    display: flex;
    flex-direction: column;
    
  }
  .value-gap {
    margin-left: 80px;
  }
  .poc_row{
    display: flex;
    justify-content: end;
    margin-right: 50px;
  }
  
  .totals {
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-weight: bold;
    float: right;
    margin-top: -90px;
  
  }