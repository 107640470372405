body {
    /* background-color: #1AA267; */
    color:black;
    font-family: Arial, sans-serif;
    margin: 20px;
}

/* h1, h2 {
    color: #1AA267;
} */

#card-view {
    /* background-color: #1AA267; */
    color: white;
    margin: 20px 0;
    padding: 20px;
    border-radius: 10px;
}

#instructions, #websiteInstructions, #mobileAppInstructions, #ganesh {
    display: none;
}

#ganesh {
    display: block;
}

#websiteInstructions {
    display: block;
}

#buttons-container {
    margin-bottom: 20px;
    display: flex;
    column-gap: 10px;
}

.image-container:hover img {
    transform: scale(1.2);
}

/* Apply hover effect to the undo button */
.undo-button:hover {
    color: red; /* Change the color or add any other styles as desired */
}

/* Center the image in the viewport */
Modal {
/* display: none; */
display: flex;
justify-content: center;
align-items: center;
position: fixed;
margin: auto;
height: 100vh;
/* top: 50%;
left: 50%; */
/* transform: translate(-50%, -50%); */
z-index: 1;
width: auto;

/* height: auto;  */
}


.close, .undo-button {
    position: absolute;
    font-size: 20px;
    cursor: pointer;
}

.undo-button {
    top: 10px;
    left: 10px;
}

.close {
    top: 10px;
    right: 10px;
}


#modalImage {
    width: 50%;
    height: 50%;
}
.showing-img{
    height: 3%;
    width:3%;
}
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
     box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); 
     z-index: 1001;
  } 
  
   .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
  } 
a{
    color: blue;
    text-decoration: underline;
}