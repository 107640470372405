.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-container.show {
    display: flex;
  }
  
  .popup-content { 
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    position: relative; 
    max-width: 65%; 
    max-height: 75%;
  }
  
  .popup-close {
    position: absolute;
    top: 3px;
    right: 5px;
    background: #268C78;
    border: 1px solid #fff;
    font-size: 24px;
    cursor: pointer;
    color: #fff;
    border-radius: 70px;
  }
  
  h2 {
    color: #1AA260;
  }
  
  .popup-button {
    background-color: #1AA260;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    margin-top: 10px;
  }
  
  .popup-images {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .popup-images img {
    width: 80px;
    height: auto;
    margin: 0 10px;
  }

  .password-wrapper {
    position: relative;
  }
  
  .password-wrapper .form-control {
    padding-right: 40px;
  }
  
  .eye-icon{
    position: absolute;
    top: 67%;
    right: 140px;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 10; 
  }
  
  .eye-icons{
    position: absolute;
    top: 83%;
    right: 30px;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 10; 
  }