.transaction-history {
  padding: 20px;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  margin: auto;
  background-color: white;
  border: 1px solid #ddd;
  height: calc(100vh - 40px); /* Adjust height as needed */
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: none;
  width: 100%;
  background-color: #086351;
  color: white;
  padding: 10px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #086351;
  border-top: 1px solid #ddd;
  color: white;
  margin-top: auto; /* Push footer to bottom */
}

.transaction-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px; /* Adjust space below header */
  table-layout: fixed; /* Fix table width */
}

.transaction-table th,
.transaction-table td {
  border: 2px solid #ddd;
  padding: 8px;
  text-align: left;
  overflow-wrap: break-word; /* Break long words */
}

.transaction-table tbody {
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: calc(100vh - 180px); /* Adjust max height to fit within view */
}

.left-section {
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-bottom: 5px;
}

.right-section {
  display: flex;
  align-items: center;
  padding-right: 15px;
}

.profile-pic {
  width: 250px;
  height: 50px;
}

.ad-banner img {
  width: 100%;
  margin: 20px 0;
}

.transaction-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.transaction-table th {
  color: black;
}

.transaction-summary {
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
  padding: 20px;
  border: 2px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.summary-details {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
  border: 2px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  padding: 10px;
}

.summary-details > div {
  flex: 1;
  text-align: center;
  border-right: 2px solid #ddd;
  padding: 10px;
  
}

.summary-details > div:last-child {
  border-right: none;
}

.summary-details b {
  display: block;
  margin-bottom: 5px;
}

.left-section .rounded-circle {
  width: 50px;
  height: 50px;
  background-color: #DDD5CC;
  margin-left: -10px;
  margin-top: 10px;
  color: black;
}

.phimg {
  color: white;
  font-size: 1.2em;
  width: 5px;
}

.bi-telephone-fill {
  width: 18px;
  height: 20px;
}

/* Media query for A4 sheet size */
@media print {
  .transaction-history {
    width: 210mm;
    height: 297mm;
    padding: 20mm;
  }
}

/* Media query for larger screens (desktops) */
@media (min-width: 1024px) {
  .transaction-history {
    width: 80%;
    max-width: 900px;
  }
  .call-text {
    display: inline;
  }
}

/* Media query for tablets */
@media (max-width: 1224px) and (min-width: 768px) {
  .transaction-history {
    width: 90%;
  }

  .header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .left-section, .right-section {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .call-text {
    display: inline;
  }
}

/* Media query for small screens (mobiles) */
@media (max-width: 425px) and (min-width: 320px) {
  .transaction-history {
    width: 90%;
  }

  .header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .left-section, .right-section {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .call-text {
    display: inline;
  }
}

@media (max-width: 768px) {
  .right-section {
    padding-right: 5px;
  }
  
  .summary-details > div {
    padding: 1px;
    overflow-wrap: break-word;
  }

  .makecall {
    font-size: 10px;
    white-space: nowrap;
    /* visibility: hidden; */
  }
  .call-text {
    display: none;
  }
  .phimg {
    height: 30px;
    width: 30px;
  }

  .profile-pic {
    width: 100px;
    height: 20px;
  }

  .left-section {
    display: flex;
    align-items: center;
    padding-left: 15px;
    padding-bottom: 5px;
    font-size: 10px;
  }

  .left-section .rounded-circle {
    width: 30px;
    height: 30px;
    background-color: #DDD5CC;
    margin-left: -10px;
    margin-top: 10px;
    font-size: 15px;
  }

  .bi-telephone-fill {
    width: 10px;
    height: 10px;
  }
  .footer {
   font-size: 12px;
  }
}
