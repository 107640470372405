/* .home-area {
    position: relative;
    /* background: url('../assets/css/img/header.jpg') no-repeat center center; */
/* background-size: cover;
    height: 100vh;
    color: white;
    text-align: left;
    display: flex;
    align-items: center;
  }
  
  .overlay {
    background-color: rgba(7, 218, 102, 0.5); */
/* width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .mobile-image img {
    width: 100%;
  }
  
  .desc ul {
    list-style: none;
    padding-left: 0;
  }
  
  .desc ul li {
    margin-bottom: 10px;
  }
  
  .space-20 {
    height: 20px;
  }
  
  .space-80 {
    height: 80px;
  }
  
  figure img {
    width: 150px;
    margin: 0 10px;
  } */

/* KhataTracker.css */

/* General styling */
.khata-tracker {
  background: linear-gradient(135deg, #2D826A 50%, #82D4bb 100%);
  color: white;
 
}
@media (min-width: 2100px){
  .khata-tracker {
    background: linear-gradient(135deg, #2D826A 50%, #82D4bb 100%);
    color: white;
    height: 100vh;
  }
}
/* Text section */
.text-section h1 {
  font-size: 2.7rem;
}

.text-section p {
  font-size: 1.1rem;
}

.text-section .app-store img {
  width: 150px;
}

/* Device images */
.device-images img {
  max-width: 100%;
  margin-right: 10px;
}

/* Custom sizing for smaller screens */
@media (max-width: 768px) {
  .text-section h1 {
    font-size: 1.9rem;
  }

  .device-images img {
    max-width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 576px) {
  .text-section h1 {
    font-size: 1.7rem;
  }

  .device-images img {
    max-width: 100%;
  }
}

.trail {
  background: #086351;
  color: #fff !important;
  border-radius: 25px;
  border: none;
  padding: 5px 25px;

}

.trails {
  background: none;
  color: #fff;
  border-radius: 25px;
  border: 1px solid #fff;
  padding: 5px 25px;
  margin-left: 10px;

}

.play-store {
  /* border: 1px solid ; */
  background-color: rgba(187, 248, 236, 0.3);
  /* opacity: 0.6; */
  padding: 10px;
  width: 60.5%;
  padding-left: 10px;
  border-radius: 10px;
}

/* @media(min-width:2100px){
  .play-store {
  width: 30%;
}
} */
/* .app-store{
  opacity: none;
} */
/* .display-4{
  font-family: Nunito;
  font-weight: 800;
font-size: 74px;
} */


    /* default styles here for older browsers. 
       I tend to go for a 600px - 960px width max but using percentages
    */
    @media only screen and (min-width: 960px) {
      .khata-tracker {
       height: 98vh;
       
      }
    }
    @media only screen and (min-width: 1440px) {
      .khata-tracker {
        height: 98vh;
        
       }
    }
    @media only screen and (min-width: 2000px) {
      .khata-tracker {
        height: 100vh;
        
       }
    }
    @media only screen and (max-device-width: 480px) {
      .khata-tracker {
        background: linear-gradient(135deg, #2D826A 50%, #82D4bb 100%);
        color: white;
       
      }
    }
    @media only screen and (device-width: 768px) {
      .khata-tracker {
        background: linear-gradient(135deg, #2D826A 50%, #82D4bb 100%);
        color: white;
       
      }
    }
    /* different techniques for iPad screening */
    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
      .khata-tracker {
        background: linear-gradient(135deg, #2D826A 50%, #82D4bb 100%);
        color: white;
       
      }
    }

    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
      /* For landscape layouts only */.khata-tracker {
  background: linear-gradient(135deg, #2D826A 50%, #82D4bb 100%);
  color: white;
 
}    }
